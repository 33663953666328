<template>
  <div>
    <v-row justify="center" dense>
      <v-col cols="11">
        <v-row>
          <v-col cols="auto">
            <span>Pool</span>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto">
            <v-btn tile small @click="addDialog = true" class="text-right" outlined>New</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="11">
        <v-data-table :headers="headers" :items="items" class="elevation-1" hide-default-footer :loading="loading" disable-sort dense >
          <template v-slot:body>
            <tbody>
              <tr v-for="item in items">
                <td class="pl-5">{{ item.id }}</td>
                <td>{{ item.poolName }}</td>
                <td>{{ item.symbol }}</td>
                <td>{{ item.address }}</td>
                <td>{{ item.platform }}</td>
                <td>{{ item.expansion }}</td>
                <td>{{ item.parent }}</td>
                <td>
                  <v-btn tile class="mr-3" @click="editPool(item)" outlined x-small>Edit</v-btn>
                  <v-btn tile outlined color="red" x-small @click="delPool(item)">Delete</v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
        <v-row v-if="loading">
          <v-col cols="12">
              <v-skeleton-loader dense type="table-row-divider@4" class="mx-auto"></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- 新增用的模态框 -->
    <v-dialog v-model="addDialog" width="500">
      <v-card>
        <v-card-title>
          Add New Pool Address
          <v-spacer></v-spacer>
          <v-btn icon @click="addDialog = false">
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="addForm">
            <v-row dense>
              <v-col cols="6">
                <v-text-field v-model="poolObj.poolName" label="Name" required clearable></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="poolObj.symbol" label="Symbol" required clearable></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="poolObj.address" label="Address" clearable></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select v-model="poolObj.platform" :items="platforms" label="Platform" hide-details></v-select>
              </v-col>
              <v-col cols="6">
                <v-switch v-model="poolObj.expansion" :label="'Expansion : '+ (poolObj.expansion == null ? 'false' : poolObj.expansion )"></v-switch>
              </v-col>
              <v-col cols="6">
                <v-switch v-model="poolObj.parent" :label="'Parent : '+ (poolObj.parent == null ? 'false' : poolObj.parent )" ></v-switch>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn tile @click="addDialog = false" outlined>Calcel</v-btn>
          <v-btn tile @click="savePool()" outlined color="green" :loading="btLoading">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 编辑用的模态框 -->
    <v-dialog v-model="editDialog" width="500">
      <v-card tile>
        <v-card-title>
          Edit Pool Address
          <v-spacer></v-spacer>
          <v-btn icon @click="editDialog = false"><v-icon>fas fa-times</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="editForm">
            <v-row dense>
              <v-col cols="6">
                <v-text-field v-model="editObj.id" label="Id" required disabled></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="editObj.symbol" label="Symbol" required clearable></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="editObj.poolName" label="Name" required clearable></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="editObj.address" label="Address" clearable></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select v-model="editObj.platform" :items="platforms" label="Platform" hide-details></v-select>
              </v-col>
              <v-col cols="6">
                <v-switch v-model="editObj.expansion" :label="'Expansion : '+editObj.expansion"></v-switch>
              </v-col>
              <v-col cols="6">
                <v-switch v-model="editObj.parent":label="'Parent : '+editObj.parent"></v-switch>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="mr-5">
          <v-spacer></v-spacer>
          <v-btn tile @click="editDialog = false" outlined>取消</v-btn>
          <v-btn tile @click="savePoolAddress()" outlined color="blue" :loading="btLoading">确定</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 删除使用的模态框 -->
    <v-dialog v-model="delDialog" width="500">
      <v-card tile>
        <v-card-title>
          Delete Pool Address
          <v-spacer></v-spacer>
          <v-btn icon @click="delDialog = false"><v-icon>fas fa-times</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="delForm">
            <v-row dense>
              <v-col cols="6">
                <v-text-field v-model="delObj.id" label="Id"disabled></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="delObj.symbol" label="Symbol"disabled></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="delObj.poolName"label="Name" required disabled></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="delObj.address" label="Address" disabled ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select v-model="delObj.platform" :items="platforms" label="Platform" disabled hide-details></v-select>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="delObj.expansion" label="Expansion" required disabled ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="delObj.parent" label="Parent" required disabled ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="mr-5">
          <v-spacer></v-spacer>
          <v-btn tile @click="delDialog = false" outlined>取消</v-btn>
          <v-btn tile @click="delPoolbyId(delObj.id)" outlined color="red" :loading="btLoading">确定</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import config from "@/config";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      loading: false,
      items: [],
      headers: [
        { text: "Id", value: "id" },
        { text: "Name", value: "name" },
        { text: "Symbol", value: "symbol" },
        { text: "Address", value: "address" },
        { text: "Platform", value: "platform" },
        { text: "Expansion", value: "expansion" },
        { text: "Parent", value: "parent" },
        { text: "Action" },
      ],
      addDialog:false,
      editDialog:false,
      delDialog:false,
      btLoading:false,
      loading:false,
      platforms: [],
      poolObj: {
        poolName: null,
        symbol:null,
        address: null,
        platform:null,
        expansion:false,
        parent:false,
      },
      editObj: {},
      delObj: {},
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  watch: {},
  mounted() {},
  created() {
    this.getPoolAddress();
    this.getPlatforms();
  },
  methods: {
    getPoolAddress() {
      this.btLoading = false;
      this.loading = true;
      this.items = [];
      let api = config.baseUrl + "/admin/private/poolAddress";
      this.loading = true;
      this.$http.get(api).then((res) => {
        let data = res.data;
        if (data.code == 200) {
          this.items = data.result;
          this.loading = false;
          this.btLoading = false;
        } else {
          this.loading = false;
          this.btLoading = false;
        }
      });
    },
    editPool(pool){
      this.editObj = JSON.parse(JSON.stringify(pool));
      this.editDialog = true;
    },
    delPool(pool) {
      this.delObj = JSON.parse(JSON.stringify(pool));
      this.delDialog = true;
    },
    savePool() {
      this.btLoading = true;
      console.log(this.poolObj);
      var param = {
        poolName: this.poolObj.poolName,
        symbol: this.poolObj.symbol,
        address: this.poolObj.address,
        platform: this.poolObj.platform,
        expansion: this.poolObj.expansion,
        parent: this.poolObj.parent,
      };
      let api = config.baseUrl + "/admin/private/poolAddress";
      this.$http.post(api, param).then((res) => {
        let data = res.data;
        if (data.code == 200) {
          this.$store.dispatch("snackbarMessageHandler", data.message);
          this.addDialog = false;
          this.getPoolAddress();
          this.reset();
        } else {
          this.$store.dispatch("snackbarMessageHandler", data.message);
        }
      });
    },
    savePoolAddress() {
      this.btLoading = true;
      var param = {
        id: this.editObj.id,
        poolName: this.editObj.poolName,
        symbol: this.editObj.symbol,
        address: this.editObj.address,
        platform: this.editObj.platform,
        expansion: this.editObj.expansion,
        parent: this.editObj.parent,
      };
      let api = config.baseUrl + "/admin/private/poolAddress";
      this.$http.post(api, param).then((res) => {
        let data = res.data;
        if (data.code == 200) {
          this.$store.dispatch("snackbarMessageHandler", data.message);
          this.editDialog = false;
          this.getPoolAddress();
          this.reset();
        } else {
          this.$store.dispatch("snackbarMessageHandler", data.message);
        }
      });
    },
    delPoolbyId(id){
      this.btLoading = true;
      let api = config.baseUrl + "/admin/private/poolAddress/" + id;
      this.$http.delete(api).then((res) => {
        let data = res.data;
        if (data.code == 200) {
          this.delDialog = false;
          this.getPoolAddress();
          this.$store.dispatch("snackbarMessageHandler", data.message);
        } else {
          this.$store.dispatch("snackbarMessageHandler", data.message);
        }
      });
    },
    // 查询所有的平台
    getPlatforms(){
      let api = config.baseUrl + '/pool/public/platforms';
      this.$http.get(api).then(response => {
          if (response.data.code == 200) {
              this.platforms = response.data.result;
          }
      })
    },
    reset() {
      this.$refs.addForm.reset();
      this.$refs.editForm.reset();
    },
  },
};
</script>

<style>
</style>