<template>
  <div>
    <v-row justify="center" dense>
      <v-col cols="11">
        <v-row>
          <v-col cols="auto">
            <span>Pool Supply Address</span>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto">
            <v-btn tile small @click="addDialog = true" class="text-right" outlined>New</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="11">
        <v-data-table :headers="headers" :items="items" hide-default-footer :loading="loading" disable-sort dense class="elevation-1">
          <template v-slot:body>
            <tbody>
              <tr v-for="item in items">
                <td class="pl-5">{{ item.id }}</td>
                <td>{{ item.symbol }}</td>
                <td>{{ item.address }}</td>
                <td>{{ item.holdAll }}</td>
                <td>
                  <v-btn tile class="mr-3" @click="editPool(item)" outlined x-small>Edit</v-btn>
                  <v-btn tile outlined color="red" x-small @click="delPool(item)">Delete</v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
        <v-row v-if="loading">
          <v-col cols="12">
              <v-skeleton-loader dense type="table-row-divider@4" class="mx-auto"></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- 新增用的模态框 -->
    <v-dialog v-model="addDialog" width="500">
      <v-card>
        <v-card-title>
          Add New Pool Supply Address
          <v-spacer></v-spacer>
          <v-btn icon @click="addDialog = false">
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="addForm">
            <v-row dense>
              <v-col cols="6">
                <v-text-field v-model="poolObj.symbol" label="Symbol" required clearable></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="poolObj.address" label="Address" clearable></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-switch v-model="poolObj.holdAll" :label="'Hold All : '+ (poolObj.holdAll == null ? 'false' : poolObj.holdAll )" ></v-switch>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn tile @click="addDialog = false" outlined>Calcel</v-btn>
          <v-btn tile @click="savePoolSupplyAddress('save')" outlined color="green" :loading="btLoading">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 编辑用的模态框 -->
    <v-dialog v-model="editDialog" width="500">
      <v-card tile>
        <v-card-title>
          Edit Pool Supply Address
          <v-spacer></v-spacer>
          <v-btn icon @click="editDialog = false"><v-icon>fas fa-times</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="editForm">
            <v-row>
              <v-col cols="6">
                <v-text-field v-model="editObj.id" label="Id" required clearable></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="editObj.symbol" label="Symbol" required clearable></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="editObj.address" label="Address" required clearable></v-text-field>
              </v-col>
              <v-col cols="12">
                 <v-switch v-model="editObj.holdAll" :label="'Hold All : '+editObj.holdAll"></v-switch>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="mr-5">
          <v-spacer></v-spacer>
          <v-btn tile @click="editDialog = false" outlined>取消</v-btn>
          <v-btn tile @click="savePoolSupplyAddress('edit')" outlined color="blue" :loading="btLoading">确定</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
     <!-- 删除使用的模态框 -->
    <v-dialog v-model="delDialog" width="500">
      <v-card tile>
        <v-card-title>
          Delete Pool Supply Address
          <v-spacer></v-spacer>
          <v-btn icon @click="delDialog = false"><v-icon>fas fa-times</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="delForm">
            <v-row dense>
              <v-col cols="6">
                <v-text-field v-model="delObj.id" label="Id"disabled></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="delObj.symbol" label="Symbol"disabled></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="delObj.address" label="Address" disabled ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="delObj.holdAll" label="Hold All" required disabled ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="mr-5">
          <v-spacer></v-spacer>
          <v-btn tile @click="delDialog = false" outlined>取消</v-btn>
          <v-btn tile @click="delPoolbyId(delObj.id)" outlined color="red" :loading="btLoading">确定</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import config from "@/config";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      // 查询加载
      loading: false,
      // 保存 删除 更新加载
      btLoading:false,
      // 数据
      items:[],
      // 表头
      headers:[
        {text:"Id"},
        {text:"Symbol"},
        {text:"Address"},
        {text:"Hold All"},
        {text:"Action"},
      ],
      // 编辑
      editDialog:false,
      editObj:{},
      // 新增
      addDialog:false,
      poolObj: {
        address: null,
        symbol:null,
        holdAll: false,
      },
      // 删除
      delDialog:false,
      delObj:{},
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  watch: {},
  mounted() {},
  created() {
    this.getPoolSupplyAddress();
  },
  methods: {
    // 获取所有地址
    getPoolSupplyAddress() {
      this.btLoading = false;
      this.loading = true;
      this.items = [];
      let api = config.baseUrl + "/admin/private/poolSupplyAddress";
      this.loading = true;
      this.$http.get(api).then((res) => {
        let data = res.data;
        if (data.code == 200) {
          this.items = data.result;
          this.loading = false;
          this.btLoading = false;
        } else {
          this.loading = false;
          this.btLoading = false;
        }
      });
    },
    // 编辑
    editPool(pool){
      this.editObj = JSON.parse(JSON.stringify(pool));
      this.editDialog = true;
    },
    // 保存/更新
    savePoolSupplyAddress(type){
      this.btLoading = true;
      if(type != null){
        var param = null;
        if(type == "save"){
          param = {
            symbol: this.poolObj.symbol,
            address: this.poolObj.address,
            holdAll: this.poolObj.holdAll,
          };
        }
        if(type == "edit"){
          param = {
            id: this.editObj.id,
            symbol: this.editObj.symbol,
            address: this.editObj.address,
            holdAll: this.editObj.holdAll,
          };
        }
        let api = config.baseUrl + "/admin/private/poolSupplyAddress";
        this.$http.post(api, param).then((res) => {
          let data = res.data;
          if (data.code == 200) {
            this.$store.dispatch("snackbarMessageHandler", data.message);
            this.editDialog = false;
            this.addDialog = false;
            this.getPoolSupplyAddress();
            this.reset();
          } else {
            this.btLoading = false;
            this.addDialog = false;
            this.editDialog = false;
            this.$store.dispatch("snackbarMessageHandler", data.message);
          }
        });
      }
    },
    // 删除
    delPool(pool) {
      this.delObj = JSON.parse(JSON.stringify(pool));
      this.delDialog = true;
    },
    delPoolbyId(id){
      this.btLoading = true;
      let api = config.baseUrl + "/admin/private/poolSupplyAddress/" + id;
      this.$http.delete(api).then((res) => {
        let data = res.data;
        if (data.code == 200) {
          this.delDialog = false;
          this.btLoading = false;
          this.getPoolAddress();
          this.$store.dispatch("snackbarMessageHandler", data.message);
        } else {
          this.delDialog = false;
          this.btLoading = false;
          this.$store.dispatch("snackbarMessageHandler", data.message);
        }
      });
    },
    // 重置 
    reset() {
      this.$refs.addForm.reset();
      this.$refs.editForm.reset();
    },
  },
};
</script>

<style>
</style>