<template>
  <div>
    <v-row justify="center" dense>
      <v-col cols="11">
        <v-row>
          <v-col cols="auto">
            <span>Asset</span>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto">
            <v-btn tile small @click="addDialog = true" class="text-right" outlined>New</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="11">
        <v-data-table :headers="headers" :items="items" class="elevation-1" hide-default-footer hide-default-header :loading="loading" disable-sort dense>
        <template v-slot:header="{ props: { headers } }">
          <thead>
            <tr>
              <th>Id</th>
              <th>Symbol</th>
              <th>Address</th>
              <th>Name</th>
              <th>
                <a href="https://api.coingecko.com/api/v3/coins/list" target="_blank" class="del-underline">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      Coin
                      <i class="fas fa-info-circle" color="primary" v-bind="attrs" v-on="on"></i>
                    </template>
                    <span>coingecko的对应币的名字(选择ID)</span>
                  </v-tooltip>
                </a>
              </th>
              <th>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      Coin Symbol
                      <i class="fas fa-info-circle" color="primary" v-bind="attrs" v-on="on"></i>
                    </template>
                    <span>coingecko的对应币的符号(存入数据库使用)</span>
                  </v-tooltip>
              </th>
              <th>Decimal</th>
              <th>Action</th>
              
            </tr>
          </thead>
        </template>
          <template v-slot:body>
            <tbody>
              <tr v-for="item in items">
                <td class="pl-5">{{ item.id }}</td>
                <td>{{ item.symbol }}</td>
                <td>{{ item.address }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.coin }}</td>
                <td>{{ item.coinSymbol }}</td>
                <td>{{ item.tokenDecimal }}</td>
                <td>
                  <v-btn tile class="mr-3" @click="editAsset(item)" outlined x-small>Edit</v-btn>
                  <v-btn tile @click="delAsset(item)" outlined color="red" x-small>Delete</v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
        <v-row v-if="loading">
          <v-col cols="12">
              <v-skeleton-loader dense type="table-row-divider@5" class="mx-auto"></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- 新增用的模态框 -->
    <v-dialog v-model="addDialog" width="500">
      <v-card tile>
        <v-card-title>
          Add New Asset Address
          <v-spacer></v-spacer>
          <v-btn icon @click="addDialog = false">
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="addForm">
            <v-row dense>
              <v-col cols="6">
                <v-text-field v-model="assetObj.symbol" label="Symbol" required clearable></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="assetObj.name" label="Name" required clearable></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="assetObj.coin" label="Coin" clearable></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="assetObj.coinSymbol" label="Coin Symbol" clearable></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="assetObj.tokenDecimal" label="Decimal" required clearable></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="assetObj.address" label="Address" required clearable ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="mr-5">
          <v-spacer></v-spacer>
          <v-btn tile @click="addDialog = false" outlined>Calcel</v-btn>
          <v-btn tile @click="saveAsset()" class="mr-5" outlined color="green" :loading="btnLoading">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 编辑用的模态框 -->
    <v-dialog v-model="editDialog" width="500">
      <v-card tile>
        <v-card-title>
          Edit Asset Address
          <v-spacer></v-spacer>
          <v-btn icon @click="editDialog = false"><v-icon>fas fa-times</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="editForm">
            <v-row dense>
              <v-col cols="6">
                <v-text-field v-model="editObj.id" label="Id" required disabled></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="editObj.symbol" label="Symbol" required clearable></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="editObj.name" label="Name" required clearable></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="editObj.coin" label="Coin" clearable></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="editObj.coinSymbol" label="Coin Symbol" clearable></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="editObj.tokenDecimal" label="Decimal" required clearable></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="editObj.address" label="Address" required clearable></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="mr-5">
          <v-spacer></v-spacer>
          <v-btn tile @click="editDialog = false" outlined>取消</v-btn>
          <v-btn tile @click="saveEditAsset()" outlined color="blue" :loading="btnLoading">确定</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 删除使用的模态框 -->
    <v-dialog v-model="delDialog" width="500">
      <v-card tile>
        <v-card-title>
          Delete Asset Address
          <v-spacer></v-spacer>
          <v-btn icon @click="delDialog = false"
            ><v-icon>fas fa-times</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="editForm">
            <v-row dense>
              <v-col cols="6">
                <v-text-field v-model="delObj.id" label="Id" required disabled></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="delObj.symbol" label="Symbol" required disabled></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="delObj.name" label="Name" required disabled></v-text-field>
              </v-col>
              <v-col cols="6" v-if=" delObj.coin != null">
                <v-text-field v-model="delObj.coin" label="Coin" disabled></v-text-field>
              </v-col>
              <v-col cols="6" v-if=" delObj.coinSymbol != null">
                <v-text-field v-model="delObj.coinSymbol" label="Coin Symbol" disabled></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="delObj.address" label="Address" required disabled></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="mr-5">
          <v-spacer></v-spacer>
          <v-btn tile @click="delDialog = false" outlined>取消</v-btn>
          <v-btn tile @click="delAssetbyId(delObj.id)" outlined color="red" :loading="btnLoading">确定</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import config from "@/config";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      loading: false,
      items: [],
      headers: [
        { text: "Id", value: "id" },
        { text: "Symbol", value: "symbol" },
        { text: "Address", value: "address" },
        { text: "Name", value: "name" },
        { text: "Coin", value: "coin" },
        { text: "Coin Symbol", value: "coinSymbol" },
        { text: "Decimal", value: "tokenDecimal" },
        { text: "Action" },
      ],
      addDialog: false,
      editDialog: false,
      delDialog: false,
      loading:false,
      assetObj: {
        symbol: null,
        name: null,
        address: null,
        coin: null,
        coinSymbol: null,
        tokenDecimal: null,
      },
      editObj: {},
      delObj: {},
      btnLoading:false,
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  watch: {},
  mounted() {},
  created() {
    this.getAssetAddress();
  },
  methods: {
    getAssetAddress() {
      this.btnLoading = false;
      this.items = [];
      let api = config.baseUrl + "/admin/private/assetAddress";
      this.loading = true;
      this.$http.get(api).then((res) => {
        let data = res.data;
        if (data.code == 200) {
          this.items = data.result;
          this.loading = false;
          this.btnLoading = false;
        } else {
          this.loading = false;
          this.btnLoading = false;
          this.$store.dispatch("snackbarMessageHandler", res.data.message);
        }
      });
    },
    saveAsset() {
      this.btnLoading = true;
      var param = {
        symbol: this.assetObj.symbol,
        name: this.assetObj.name,
        address: this.assetObj.address,
        coin: this.assetObj.coin,
        coinSymbol: this.assetObj.coinSymbol,
        tokenDecimal: this.assetObj.tokenDecimal,
      };
      let api = config.baseUrl + "/admin/private/assetAddress";
      this.$http.post(api, param).then((res) => {
        let data = res.data;
        if (data.code == 200) {
          this.$store.dispatch("snackbarMessageHandler", data.message);
          this.addDialog = false;
          this.getAssetAddress();
          this.reset();
        } else {
          this.btnLoading = false;
          this.$store.dispatch("snackbarMessageHandler", data.message);
        }
      });
    },
    saveEditAsset() {
      this.btnLoading = true;
      var param = {
        id: this.editObj.id,
        symbol: this.editObj.symbol,
        name: this.editObj.name,
        address: this.editObj.address,
        coin: this.editObj.coin,
        coinSymbol: this.editObj.coinSymbol,
        tokenDecimal: this.editObj.tokenDecimal,
      };
      let api = config.baseUrl + "/admin/private/assetAddress";
      this.$http.post(api, param).then((res) => {
        let data = res.data;
        if (data.code == 200) {
          this.$store.dispatch("snackbarMessageHandler", data.message);
          this.editDialog = false;
          this.getAssetAddress();
          this.reset();
        } else {
          this.btnLoading = false;
          this.$store.dispatch("snackbarMessageHandler", data.message);
        }
      });
    },
    editAsset(asset) {
      this.editObj = JSON.parse(JSON.stringify(asset));
      this.editDialog = true;
    },
    delAsset(asset) {
      this.delObj = JSON.parse(JSON.stringify(asset));
      this.delDialog = true;
    },
    delAssetbyId(id) {
      this.btnLoading = true;
      let api = config.baseUrl + "/admin/private/assetAddress/" + id;
      this.$http.delete(api).then((res) => {
        let data = res.data;
        if (data.code == 200) {
          this.delDialog = false;
          this.getAssetAddress();
          this.$store.dispatch("snackbarMessageHandler", data.message);
        } else {
          this.btnLoading = false;
          this.$store.dispatch("snackbarMessageHandler", data.message);
        }
      });
    },
    reset() {
      this.$refs.addForm.reset();
    },
  },
};
</script>

<style>
</style>