<template>
    <div>
        <v-container fluid class="pt-0 mt-5">
            <v-row>
                <v-col cols="12">
                    <v-alert :dismissible="false" class="deep-orange darken-2 white--text" dark border="left" icon="fas fa-align-left">
                        <v-row no-gutters class="align-center">
                            <span>Address Management</span>
                            <v-spacer></v-spacer>
                            <v-btn tile small outlined class="mr-3" @click="comp = 'pool'">Pool</v-btn>
                            <v-btn tile small outlined class="mr-3" @click="comp = 'asset'">Asset</v-btn>
                            <v-btn tile small outlined class="mr-3" @click="comp = 'supply'">Supply</v-btn>
                        </v-row>
                    </v-alert>
                </v-col>
            </v-row>
            <pool-address v-if="comp == 'pool'"></pool-address>
            <asset-address v-if="comp == 'asset'"></asset-address>
            <pool-supply-address v-if="comp == 'supply'"></pool-supply-address>
        </v-container>
    </div>
</template>

<script>
    import Vue from 'vue';
    import config from '@/config';
    import { mapGetters } from "vuex";
    import PoolAddress from "./components/PoolAddress";
    import AssetAddress from "./components/AssetAddress";
    import PoolSupplyAddress from "./components/PoolSupplyAddress";
    export default {
        data() {
            return {
                comp:"pool",
            }
        },
        computed: {
            ...mapGetters(['user']),
        },
        components: {
            PoolAddress,
            AssetAddress,
            PoolSupplyAddress,
        },
        watch: {
            
        },
        mounted() {
            if (!this.user.token) {
                this.$router.push({ name: 'login' })
            };
        },
        created() {
           
        },
        methods: {
           
        }
    }
</script>

<style>

</style>